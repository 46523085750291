import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "481px",
  md: "1024px",
  lg: "1367px",
  xl: "1400px",
  "2xl": "1400px",
});

export const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      "html, body": {
        color: "low.dark",
      },
      "body, #root": {
        background: "high.light",
        overflow: "hidden",
        position: "relative",
        fontSize: "14px",
      },
    },
  },
  fonts: {
    body: "Sora, sans-serif",
    heading: "Sora, sans-serif",
  },
  colors: {
    primary: {
      pure: "#1155BB",
      light: "#3BB2EE",
      medium: "#166DD7",
      dark: "#113377",
    },
    highlight: {
      pure: "#8855EE",
      light: "#BB88FF",
      medium: "#9055FF",
      dark: "#7044BB",
    },
    low: {
      pure: "#000000",
      light: "#BABABA",
      medium: "#606060",
      dark: "#303030",
    },
    high: {
      pure: "#FFFFFF",
      light: "#FBFBFB",
      medium: "#F0F0F0",
      dark: "#E5E5E5",
    },
    error: {
      pure: "#D13F3F",
      light: "#E5ACAC",
      medium: "#D16161",
      dark: "#B22424",
    },
    alert: {
      pure: "#F48619",
      light: "#FFB266",
      medium: "#F29130",
      dark: "#B25900",
    },
    success: {
      pure: "#008844",
      light: "#6CD9A0",
      medium: "#22BB6B",
      dark: "#138047 ",
    },
  },
});
