import {
  Flex,
  RadioGroup,
  Radio,
  Stack,
  Heading,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { Input } from "../../components/Form/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { Select } from "../../components/Form/Select";
import { api } from "../../services/api";

const schema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("E-mail obrigatório"),
  name: yup.string().required("Nome obrigatório"),
  document: yup
    .string()
    .required("Documento obrigatório")
    .min(14, "Verifique o documento informado"),
  phone: yup
    .string()
    .required("Telefone obrigatório")
    .min(14, "Verifique o seu telefone informado"),
  enterprise: yup.string().required("Empresa obrigatório"),

  password: yup
    .string()
    .required("Senha obrigatória")
    .min(6, "No mínimo 6 caracteres"),
  password2: yup
    .string()
    .oneOf([null, yup.ref("password")], "As senhas precisão ser iguais"),
});

interface Props {
  name: string;
  email: string;
  document: string;
  password: string;
  password2?: string;
  phone: string;
  enterprise: string;
  type: string;
}

export const Home = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState<Props>({} as Props);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Props>({
    resolver: yupResolver(schema),
  });
  const [sys, setSys] = useState("Gestor");

  const handleComfirm: SubmitHandler<Props> = (values) => {
    setData(() => values);
    onOpen();
  };

  const handleSave = async (values: Props) => {
    try {
      setLoading(true);
      await new Promise((resolver) => setTimeout(resolver, 1000));
      const user = {
        cpf: values.document.replace(/\D/g, ""),
        email: values.email
          .toLowerCase()
          .replace(/[&\/\\#,+``=()$~%'":*?<>{}]/g, ""),
        FirstOrigin: sys,
        name: values.name.replace(/[&\/\\#,+=()$%'":*?<>{}]/g, ""),
        password: values.password,
        perfilId: 1,
        phone: values.phone.replace(/\D/g, ""),
        type: Number(values.type),
      };
      const company = {
        cnpjCpf: user.cpf,
        phone: user.phone,
        password: user.password,
        BusinessName: "",
        CellPhone: null,
        Description: null,
        Email: user.email,
        Facebook: null,
        FictitiousName: values.enterprise,
        Image: null,
        MEI: null,
        Password: "",
        SerieNFCE: null,
        Status: 0,
        Time: null,
        TokenNFCE: null,
        Twitter: null,
        WebSite: null,
      };

      await api.post(
        "CompanyUser",
        { user, company },
        {
          headers: {
            cnpjCpf: company.cnpjCpf,
          },
        }
      );

      setLoading(false);

      toast({
        title: "Sucesso!",
        description: "Cadastro realizado com sucesso 🙌",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      handleReset();
      window.location.replace("https://workspace.londrisoft.com.br/");
    } catch (error) {
      setLoading(false);
      toast({
        title: "Falha ao cadastrar 🤡!",
        description:
          "Empresa já registrada com esse cnpj ou email, revise os dados",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleReset = () => {
    onClose();
    setData({} as Props);
    setSys("Gestor");
    reset();
  };

  return (
    <>
      <Flex
        height="100vh"
        width="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        overflowY="auto"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          width="850px"
          padding="24px 127px 24px 127px"
          transform={{ base: "scale(0.9)", lg: "scale(1)" }}
        >
          <Flex display={{ base: "none", lg: "flex" }}>
            <Logo />
          </Flex>

          <Stack
            direction="column"
            spacing={4}
            width="100%"
            marginTop="36px"
            as="form"
            onSubmit={handleSubmit(handleComfirm)}
          >
            <Heading fontSize="18px">Dados do Cliente</Heading>
            <Stack direction="row" spacing={2}>
              <Input
                {...register("name")}
                label="Nome do Cliente"
                placeholder="John Doe"
                error={errors.name}
              />
              <Input
                {...register("email")}
                label="E-mail do Cliente"
                placeholder="johndoe@mail.com"
                error={errors.email}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Input
                {...register("phone")}
                label="Telefone"
                phone
                placeholder="00 0 0000-0000"
                error={errors.phone}
              />
              <Input
                {...register("document")}
                label="CPF OU CNPJ"
                placeholder="XXX.XXX.XXX.XX"
                doc
                error={errors.document}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Input
                {...register("password")}
                label="Senha"
                placeholder="********"
                type="password"
                error={errors.password}
              />
              <Input
                {...register("password2")}
                label="Confirme a senha"
                placeholder="*******"
                type="password"
                error={errors.password2}
              />
            </Stack>
            <Input
              {...register("enterprise")}
              label="Nome da Empresa"
              placeholder="Londrisoft - Indústria de Software"
              error={errors.enterprise}
            />
            <Stack marginTop="36px">
              <Heading fontSize="18px">Selecione o sistema</Heading>
              <RadioGroup onChange={setSys} value={sys}>
                <Stack direction="row">
                  <Radio value="Gestor">Gestor</Radio>
                  <Radio value="Unique">Unique</Radio>
                </Stack>
              </RadioGroup>
            </Stack>

            <Stack direction="row" spacing={2}>
              {sys === "Gestor" ? (
                <Select {...register("type")} label="Tipo do sistema">
                  <option value="0">Sistema Local</option>
                  <option value="1">Sistema Nuvem</option>
                </Select>
              ) : (
                <Select
                  {...register("type")}
                  label="Tipo do sistema"
                  isDisabled
                  defaultValue="0"
                >
                  <option value="0">Sistema Local</option>
                </Select>
              )}
            </Stack>
            <Button type="submit" colorScheme="blue">
              Prosseguir
            </Button>
          </Stack>
        </Flex>
      </Flex>
      {/* MODAL */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="2xl"
        closeOnEsc={loading ? false : true}
        closeOnOverlayClick={loading ? false : true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirme os dados</ModalHeader>
          <ModalCloseButton disabled={loading} />
          <ModalBody>
            <Flex direction="row">
              <Stack direction="column" spacing={4}>
                <Text fontSize="16px">
                  Nome: <strong>{data.name}</strong>
                </Text>
                <Text fontSize="16px">
                  E-mail: <strong>{data.email}</strong>
                </Text>
                <Text fontSize="16px">
                  Empresa: <strong>{data.enterprise}</strong>
                </Text>
                <Text fontSize="16px">
                  Telefone: <strong>{data.phone}</strong>
                </Text>
                <Text fontSize="16px">
                  Documento: <strong>{data.document}</strong>
                </Text>
              </Stack>
              <Stack direction="column" spacing={4} marginLeft="210px">
                <Text fontSize="16px">
                  Sistema: <strong>{sys}</strong>
                </Text>
                <Text fontSize="16px">
                  Tipo: <strong>{data.type === "0" ? "LOCAL" : "NUVEM"}</strong>
                </Text>
              </Stack>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              width="200px"
              onClick={() => handleSave(data)}
              isLoading={loading}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
