import {
  FormControl,
  FormLabel,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";

type SelectProps = ChakraSelectProps & {
  name: string;
  label: string;
};

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  { name, label, children, ...rest },
  ref
) => {
  return (
    <FormControl id={name}>
      <FormLabel
        fontWeight="600"
        fontSize="14px"
        mb="4px"
        htmlFor={name}
        color="low.dark"
      >
        {label}
      </FormLabel>
      <ChakraSelect
        name={name}
        id={name}
        ref={ref}
        {...rest}
        backgroundColor="high.pure"
        borderRadius="4"
        borderColor="low.dark"
        fontSize={{ base: "16px", sm: "16px" }}
        color="low.dark"
        _hover={{ borderColor: "low.dark" }}
      >
        {children}
      </ChakraSelect>
    </FormControl>
  );
};

export const Select = forwardRef(SelectBase);
