/* eslint-disable react/no-children-prop */
import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Icon,
} from "@chakra-ui/react";
import { toPattern } from "vanilla-masker";

import { RiEyeOffFill, RiEyeFill } from "react-icons/ri";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useState,
} from "react";
import { FieldErrors } from "react-hook-form";

type InputProps = ChakraInputProps & {
  name: string;
  label?: string;
  error?: FieldErrors;
  phone?: boolean;
  doc?: boolean;
};

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { name, label, type, error = null, phone = false, doc = false, ...rest },
  ref
) => {
  const [show, setShow] = useState(true);
  const handleClick = () => setShow(!show);

  const handleSwitchType = () => {
    if (show) {
      return "password";
    }
    return "text";
  };

  const maskPhone = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!phone) return;

      const masks = ["(99) 9999-9999", "(99) 9 9999-9999"];
      const m = e.target.value.replace(/\D/g, "").length > 10 ? 1 : 0;

      return (e.target.value = toPattern(e.target.value, {
        pattern: masks[m],
      }));
    },
    [phone]
  );
  const maskDocument = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!doc) return;

      const masks = ["999.999.999-99", "99.999.999/9999-99"];
      const m = e.target.value.replace(/\D/g, "").length > 11 ? 1 : 0;

      return (e.target.value = toPattern(e.target.value, {
        pattern: masks[m],
      }));
    },
    [doc]
  );

  const mask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (phone) {
        maskPhone(e);
        return;
      }
      if (doc) {
        maskDocument(e);
        return;
      }

      return;
    },
    [phone, doc, maskPhone, maskDocument]
  );

  return (
    <FormControl id={name} isInvalid={!!error}>
      {!!label && (
        <FormLabel
          fontWeight="600"
          fontSize="14px"
          mb="4px"
          htmlFor={name}
          color="low.dark"
        >
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <ChakraInput
          name={name}
          id={name}
          ref={ref}
          {...rest}
          backgroundColor="high.pure"
          borderRadius="4"
          borderColor="low.dark"
          _hover={{ borderColor: "low.dark" }}
          fontSize={{ base: "16px", sm: "16px" }}
          color="low.dark"
          type={type !== "password" ? type : handleSwitchType()}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => mask(e)}
        />

        {type === "password" && (
          <InputRightElement
            children={
              <Icon
                as={show ? RiEyeOffFill : RiEyeFill}
                fontSize="22px"
                color="low.dark"
                cursor="pointer"
                onClick={handleClick}
              />
            }
          />
        )}
      </InputGroup>

      {!!error && <FormErrorMessage mt="4px">{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
